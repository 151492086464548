import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

/* Main Css */
import './main.css'

/* Image */
import Office from '../image/office.jpg';
import Ball from '../image/ball.jpg';
import Mountains from '../image/mountains.jpg';
import SunRise from '../image/sunrise.jpg';
import Hex from '../image/hex.jpg';
import Paddle from '../image/Paddle Sports.jpg';
import Walk from '../image/walking.jpg';
import Plan from '../image/planing.jpg';
import Ofroad from '../image/off_road.jpg';
import App from '../image/scene.svg';
import Store from '../image/store.png';
import Play from '../image/play.png';

// CLASS
class Main extends Component {

    // display
    static displayName = "Main"

    // constructor
    constructor(props) {
        super(props);
        // STATE
        this.state = {

        }
        //BIND
    }

    // CDM

    // Function

    // RENDER
    render() {
        let data = (this.props.t('about'))
        return (
            <React.Fragment >
                {/* start codes Header */}
                <header id="header">
                    <div className="jumbotron text-white d-flex flex-column align-items-center justify-content-center">
                        <div className="container text-center">
                            <h1 className="line-clamp15 text-white">Explore Outdoor Adventures</h1>
                            <form className="search-form">
                                <input
                                    className="form-control search-input"
                                    type="search"
                                    placeholder="Search nature adventures city, park, or trail name"
                                    aria-label="Search"
                                />
                            </form>
                        </div>
                    </div>
                </header>
                {/* ends codes Header */}

                {/* start codes Main  */}
                <main>
                <section id="services_id">
                    <div className="icon_service">
                        <h2 className='text-center'>Services</h2>
                    </div>{/* end icon services */}
                </section>

                <section className="section section-header text-dark pb-md-8">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10 text-center mb-5 mb-md-7">
                                <h5 className="display-2 font-weight-bolder mb-4">Adventure Awaits.</h5>
                                <p className="lead mb-4 mb-lg-5">
                                    LuontoGO helps you remember essential details, so you can focus on the thrill of the adventure, not the planning. With our secure platform, you can explore the great outdoors with peace of mind.
                                </p>
                            </div>
                            <div className="col-12 col-md-10 justify-content-center">
                                <img className="d-none d-md-inline-block" src={App} alt="Mobile App Mockup" />
                            </div>
                        </div>
                    </div>
                    <div className="container">
                            <div className="row justify-content-center">

                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xxl-3 d-flex flex-column align-items-center">
                                    <a href="https://play.google.com/store/">
                                    <img src={Play} alt="Google Play" style={{ width: '5rem', height: '5rem' }} />
                                    </a>
                                    <h4>Google Play</h4>
                                    <p className='line-clamp4'>Coming Soon</p>
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xxl-3 d-flex flex-column align-items-center">
                                    <a href="https://www.apple.com/tr/app-store/">
                                    <img src={Store} alt="App Store" style={{ width: '5rem', height: '5rem' }} />
                                    </a>
                                    <h4>App Store</h4>
                                    <p className='line-clamp4'>Coming Soon</p>
                                </div>

                            </div> {/* end row */}
                        </div>{/* end container */}
                </section>


                    

                   


                    {/* start blog */}
                    <section id="blog_id">
                        <div className="blog_service">
                            <h2 className='text-center'>Blog</h2>
                            <div className="container">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xxl-3">
                                        <div class="card mb-4">
                                            <img class="card-img-top" src={Ball} alt="Title" />
                                            <div class="card-body">
                                                <h4 class="card-title">Snowboard</h4>
                                                <p class="card-text line-clamp5">Snowboard noktaları görseller hava durumu parkur genel bilgi notu 
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xxl-3">
                                        <div class="card">
                                            <img class="card-img-top" src={Hex} alt="Title" />
                                            <div class="card-body">
                                                <h4 class="card-title">Hiking</h4>
                                                <p class="card-text line-clamp5">Hiking noktaları görseller hava durumu genel bilgi notu 
                                                </p>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xxl-3">
                                        <div class="card">
                                            <img class="card-img-top" src={Mountains} alt="Title" />
                                            <div class="card-body">
                                                <h4 class="card-title">Road Biking </h4>
                                                <p class="card-text line-clamp5">Road Biking yolları görseller hava durumu genel bilgi notu 
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xxl-3">
                                        <div class="card">
                                            <img class="card-img-top" src={SunRise} alt="Title" />
                                            <div class="card-body">
                                                <h4 class="card-title">Camping</h4>
                                                <p class="card-text line-clamp5">Campiing noktaları görseller hava durumu genel bilgi notu 
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xxl-3">
                                        <div class="card mb-4">
                                            <img class="card-img-top" src={Paddle} alt="Title" />
                                            <div class="card-body">
                                                <h4 class="card-title">Paddle Sports</h4>
                                                <p class="card-text line-clamp5">Paddle Sports noktaları görseller hava durumu parkur genel bilgi notu 
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xxl-3">
                                        <div class="card">
                                            <img class="card-img-top" src={Walk} alt="Title" />
                                            <div class="card-body">
                                                <h4 class="card-title">Walking</h4>
                                                <p class="card-text line-clamp5">Yürüyüş noktaları görseller hava durumu genel bilgi notu 
                                                </p>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xxl-3">
                                        <div class="card">
                                            <img class="card-img-top" src={Ofroad} alt="Title" />
                                            <div class="card-body">
                                                <h4 class="card-title">Off-Road Driving </h4>
                                                <p class="card-text line-clamp5">Off-Road parkur yolları görseller hava durumu genel bilgi notu 
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xxl-3">
                                        <div class="card">
                                            <img class="card-img-top" src={Plan} alt="Title" />
                                            <div class="card-body">
                                                <h4 class="card-title">Sports Gear</h4>
                                                <p class="card-text line-clamp5">Doğa sporları için gerekli ekipman listesi ve planlama
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div> {/* end row */}
                            </div>{/* end container */}
                        </div>{/* end icon services */}
                    </section>
                    {/* end blog */}

                    {/* start about_id */}
                    <section id="about_id">
                        <div className="about_service">
                            <h2 className='text-center text-black'> About Me</h2>
                            <div className="container">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xxl-12">
                                        <i class="fa-solid fa-eject"></i>
                                        <p className='line-clamp15 text-black'>
                                            At LuontoGO, we help outdoor sports enthusiasts plan safe and enjoyable adventures in remote and 
                                            challenging terrains. Our platform, designed for activities such as snowboarding, hiking, and mountain 
                                            biking, is equipped with interactive maps, real-time weather updates, and emergency SOS features.
                                        </p>
                                        <p className='line-clamp15 text-black'>
                                            Our users can easily plan their adventures, share their experiences, and make more informed decisions 
                                            with community recommendations. Focusing on safety, preparation, and community support, the Niche 
                                            Expedition Planner makes outdoor adventures accessible and safe for everyone.
                                        </p>
                                    </div>
                                </div> {/* end row */}
                            </div>{/* end container */}
                        </div>{/* end about services */}
                    </section>  {/* end about services */}


                    {/* start contact_id */}
                    <section id="contact_id">
                        <div className="contact_service">
                            <h2 className='text-center  mb-4 text-dark text-uppercase'> Contact</h2>
                            <div className="container">
                                <div className="row">
                                    {/* MAP */}
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xxl-6">
                                        <div className="mapouter">
                                            <div className="gmap_canvas">
                                                <iframe
                                                    className="gmap_iframe"
                                                    frameBorder={0}
                                                    scrolling="no"
                                                    marginHeight={0}
                                                    marginWidth={0}
                                                    src="https://maps.google.com/maps?width=600&height=400&hl=en&q=ANKARA+(My%20Business%20Name)&iwloc=B&amp;output=embed"></iframe>
                                                <a href="https://embed-googlemap.com">embed google map</a>
                                            </div>
                                            <style
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                    `.mapouter{position:relative;text-align:right;width:100%;height:0;padding-bottom:56.25%;margin-top:30px;}
                                                    .gmap_canvas {overflow:hidden;background:none!important;width:100%;height:100%;position:absolute;top:0;left:0;}
                                                    .gmap_iframe {width:100%!important;height:100%!important;}`
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {/* FORM */}
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xxl-6">
                                        <form action="#!" method="post" autoComplete='on'>
                                            {/* Username */}
                                            <div className="form-group mt-4 mb-4">
                                                <label htmlFor="uname">Username</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="uname"
                                                    placeholder="Your User Name"
                                                    title='kullanıcı adınız'
                                                />
                                            </div>

                                            {/* Email */}
                                            <div className="form-group mb-4">
                                                <label htmlFor="uemail">Email address</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="uemail"
                                                    name="uemail"
                                                    placeholder="Your email adress"
                                                    title="Email adresiniz"
                                                />
                                            </div>

                                            {/* Subject */}
                                            <div className="form-group mb-4">
                                                <label htmlFor="usubject">Subject</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="usubject"
                                                    name="usubject"
                                                    placeholder="Subject"
                                                    title="Email Konusu"
                                                />
                                            </div>

                                            {/* Content */}
                                            <div className="form-group mb-4">
                                                <label htmlFor="exampleFormControlTextarea1">Content</label>
                                                <textarea
                                                    className="form-control"
                                                    id="exampleFormControlTextarea1"
                                                    rows={3}
                                                    defaultValue={""}
                                                />
                                            </div>

                                            <div className="form-group mt-4 mb-4">
                                                <input type="reset" id="reset" name="reset" value="Clear" className="btn btn-danger me-3" />
                                                <button type="submit" id="submit" name="submit" className="btn btn-primary">Submit </button>
                                            </div>

                                        </form>

                                    </div>
                                </div> {/* end row */}
                            </div>{/* end container */}
                        </div>{/* end about services */}
                    </section>  {/* end about services */}

                    {/* start backToTop */}
                    <a href="#header" id="backToTop" className="btn btn-outline-primary">
                        <i class="fa-solid fa-arrow-up"></i>
                    </a>

                </main>
                {/* end codes Main  */}

            </React.Fragment>
        ); //end retur
    } //end render
} //end class

// EXPORT HEADER
export default withTranslation()(Main);