import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {useState} from "react";

i18n.use(initReactI18next).init({
    resources: {
        en:
            {
                translations: {
                   'home_page':'Home Page',
                   'blog':'Blog',
                   'about':'About',
                   'contact':'Contact',
                   'search':'Search',
                   'languages':'Languages',
                   'services':'Services',
                   'register':'Register',
                   'cleaner':'Cleaner',
                   'added':'Added',
                   'updated':'Updated',
                   'username':'Username',
                   'surname':'Surname',
                   'email':'Email Address',
                   'password':'Password',
                   'systemCreatedDate':'System Created Date',
                   'update':'Update',
                   'view':'View',
                   'delete':'Delete',
                   'register_list':"Register List",
                   'register_update':"Register Update",
                   'register_view':"Register View",
                   'register_create':"Register Create",
                   'is_read':"Is Read ?",
                   'close':'Close',
                   'not_Showing':'Not Showing',
                   'admin_register':'Admin'
                }
            },
            
        tr: {
            translations: {
                'home_page': 'Etusivu',
                'blog': 'Blogi',
                'about': 'Meistä',
                'contact': 'Yhteystiedot',
                'search': 'Haku',
                'languages': 'Kielet',
                'services': 'Palvelut',
                'cleaner': 'Puhdista',
                'added': 'Lisätty',
                'updated': 'Päivitetty',
                'register': 'Rekisteröidy',
                'username': 'Käyttäjänimi',
                'surname': 'Sukunimi',
                'email': 'Sähköpostiosoite',
                'password': 'Salasana',
                'systemCreatedDate': 'Päivämäärä',
                'update': 'Päivitä',
                'view': 'Näytä',
                'delete': 'Poista',
                'register_list': 'Rekisteriluettelo',
                'register_update': 'Päivitä Rekisteri',
                'register_view': 'Näytä Rekisterit',
                'register_create': 'Lisää Rekisteri',
                'is_read': 'Oletko Lukenut?',
                'close': 'Sulje',
                'not_Showing': 'Älä Näytä Uudelleen',
                'admin_register': 'Ylläpitäjä'
            }
        }
    },
    fallbackLng: 'tr',    //fallbackLng: 'en', fall back function    
    ns: ['translations'], //kelimeleri nerede alsın
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {escapeValue: false, formatSeparator: ','},
    react: {
        wait: true
    }
});
export default i18n;