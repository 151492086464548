import React, { Component } from 'react';
// I18N
// Resuability
import OtherLanguageReusability from '../internationalization/OtherLanguageReusability';
import { withTranslation } from 'react-i18next';

// Dark Mode
import './DarkMode/dark.css';
import DarkMode from './DarkMode/DarkMode';
import { Link } from 'react-router-dom';

//logo
import logo from '../image/logo/logo.png';

// CLASS
class Header extends Component {

    // display
    static displayName = "Header"

    // constructor
    constructor(props) {
        super(props);

        // STATE
        this.state = {}

        //BIND
    }

    // CDM

    // Function

    // RENDER
    render() {

        /* JS Codes */
        const { t } = this.props;

        /* Return */
        return (
            <React.Fragment>
                
                {/* start First Navbar */}
                
                {/* end   First Navbar */}
                

                {/* start Login Off Canvas Login */}
                <div className="container-fluid ">
                    <div
                        className="offcanvas offcanvas-start bg-dark text-white"
                        tabIndex={-1}
                        id="offcanvas_login"
                        aria-labelledby="offcanvasNavbarLabel"
                    >
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title text-danger" id="offcanvasNavbarLabel">
                                {t('login')}
                            </h5>
                            <button
                                type="button"
                                className="btn-close text-reset"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                            />
                        </div>
                        <div className="offcanvas-body">
                            <form>
                                <input
                                    className="form-control me-2 mb-2"
                                    type="email"
                                    placeholder="email address"
                                />
                                <input
                                    className="form-control me-2 mb-2"
                                    type="password"
                                    placeholder="password"
                                />
                                <button className="btn btn-outline-danger mt-2 me-2" type="reset">
                                {t('cleaner')}
                                </button>
                                <button className="btn btn-outline-primary mt-2" type="submit">
                                {t('added')}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                {/* end  Login Off Canvas Login */}

                {/* start Register Off Canvas Register */}
                <div className="container-fluid ">
                    <div
                        className="offcanvas offcanvas-end bg-dark text-white"
                        tabIndex={-1}
                        id="offcanvas_register"
                        aria-labelledby="offcanvasNavbarLabel"
                    >
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title text-danger" id="offcanvasNavbarLabel">
                                {t('register')}
                            </h5>
                            <button
                                type="button"
                                className="btn-close text-reset"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                            />
                        </div>
                        <div className="offcanvas-body">
                            <form>
                                <input
                                    className="form-control me-2 mb-2"
                                    type="uname"
                                    id="uname"
                                    name="uname"
                                    title={t('username')}
                                    placeholder={t('username')}
                                />

                                <input
                                    className="form-control me-2 mb-2"
                                    type="usurname"
                                    id="usurname"
                                    name="usurname"
                                    title={t('surname')}
                                    placeholder={t('surname')}
                                />

                                <input
                                    className="form-control me-2 mb-2"
                                    type="email"
                                    id="uemail"
                                    name="uemail"
                                    title={t('email')}
                                    placeholder={t('email')}
                                />
                                <input
                                    className="form-control me-2 mb-2"
                                    type="password"
                                    id="upassword"
                                    name="upassword"
                                    title={t('password')}
                                    placeholder={t('password')}
                                />
                                <button className="btn btn-outline-danger mt-2 me-2" type="reset">
                                {t('cleaner')}
                                </button>
                                <button className="btn btn-outline-primary mt-2" type="submit">
                                {t('added')}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                {/* end Register Off Canvas Login */}


                {/* start Second Navbar */}
                <nav
                    id="navbar_second_id"
                    className="navbar navbar-expand-md"
                    
                    style={{ backgroundColor: 'white', color: '#336B69', position: 'fixed', top: 0, width: '100%' }}
                >
                    

                    <div className="container">
                        <a className="navbar-brand" href={this.props.url}>
                            <img src={logo} alt="Logo" style={{ height: '50px' }} />
                        </a>
                        <a class="navbar-brand" href="#" style={{ fontWeight: 'bold', fontSize: "2rem"}}>LuontoGo</a>
                        <button
                            className="navbar-toggler d-lg-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsibleNavId"
                            aria-controls="collapsibleNavId"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon" />
                        </button>

                        

                        <div className="collapse navbar-collapse" id="collapsibleNavId">
                            <ul className="navbar-nav me-auto mt-2 mt-lg-0">
                                
                                <li className="nav-item">
                                    <a className="nav-link active" href="#" aria-current="page" style={{ fontWeight: 'bold' }}>
                                        {this.props.t('home_page')} <span className="visually-hidden">(current)</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" href="#services_id" style={{ fontWeight: 'bold' }}>
                                        {this.props.t('services')}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" href="#blog_id" style={{ fontWeight: 'bold' }}>
                                        {this.props.t('blog')}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" href="#about_id" style={{ fontWeight: 'bold' }}>
                                        {this.props.t('about')}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" href="#contact_id" style={{ fontWeight: 'bold' }}>
                                        {this.props.t('contact')}
                                    </a>
                                </li>

                                {/* <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        href="#"
                                        id="dropdownId"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        {t('admin_register')}
                                    </a>
                                     <div className="dropdown-menu" aria-labelledby="dropdownId">
                                         
                                         <Link to="/register/list" className="dropdown-item" href="#">
                                            {t('register_list')}
                                        </Link> 
                                    </div> 
                                </li> */}


                                
                            </ul>
                            {/* language */}
                            <ul className="navbar-nav ms-auto me-3 mt-2 mt-lg-0">
                                <li className="nav-item mt-2">
                                    {/* language */}
                                    <OtherLanguageReusability />
                                </li>
                                
                                <li>
                                    <button
                                        type="button"
                                        style={{
                                            backgroundColor: '#e9e9e9',
                                            border: 'none',
                                            borderRadius: '2rem',
                                            padding: '0.4rem 1.3rem',
                                            fontSize: '1rem',
                                            color: '#000',
                                            marginRight: '0.5rem',
                                            marginLeft: '1rem',
                                            transition: 'background-color 0.3s'
                                        }}
                                        onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#949494'}
                                        onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#e9e9e9'}
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvas_login"
                                        aria-controls="offcanvasNavbar"
                                    >
                                        Log-in
                                    </button>
                                    <button
                                        type="button"
                                        style={{
                                            backgroundColor: '#e9e9e9',
                                            border: 'none',
                                            borderRadius: '2rem',
                                            padding: '0.4rem 1.3rem',
                                            fontSize: '1rem',
                                            color: '#000',
                                            transition: 'background-color 0.3s'
                                        }}
                                        onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#949494'}
                                        onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#e9e9e9'}
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvas_register"
                                        aria-controls="offcanvasNavbar"
                                    >
                                        Register
                                    </button>
                                </li>

                            </ul>
                            
                        </div>
                    </div>
                </nav>
                {/* end Second Navbar */}
            </React.Fragment>
        ); //end retur
    } //end render
} //end class

// EXPORT HEADER
export default withTranslation()(Header)


// Props Default Variables
Header.defaultProps = {
    url: "http://localhost:3000"
}

// Props Default Value Validation
// Header.propTypes = {
//     url: PropTypes.string.isRequired,
// }



