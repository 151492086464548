import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

// CLASS
class Footer extends Component {

    // display
    static displayName = "Footer"

    // constructor
    constructor(props) {
        super(props);
        // STATE
        this.state = {

        }
        //BIND
    }

    // CDM

    // Function
    firstNewDate = () => {
        return "2023"
    }

    secondNewDate(){
        return ` ${new Date().getFullYear()} ` 
    }

    // RENDER
    render() {
        return (
            <React.Fragment>
                <footer className="bg-dark text-white text-center fixed-bottom44">
                    {/* Grid container */}
                    <div className="container p-4 pb-0">
                        {/* Section: Social media */}
                        <section className="mb-4">
                            {/* Facebook */}
                            <a
                                data-mdb-ripple-init=""
                                className="btn text-white btn-floating m-1"
                                style={{ backgroundColor: "#3b5998" }}
                                href="https://www.facebook.com/LuontoGo.2024?locale=tr_TR"
                                role="button"
                            >
                                <i className="fab fa-facebook-f" />
                            </a>
                            {/* Twitter */}
                            <a
                                data-mdb-ripple-init=""
                                className="btn text-white btn-floating m-1"
                                style={{ backgroundColor: "#55acee" }}
                                href="https://x.com/LuontoGo?t=4bt8wGk9mHCfwOyF94wS8Q&s=09"
                                role="button"
                            >
                                <i className="fab fa-twitter" />
                            </a>
                            {/* Instagram */}
                            <a
                                data-mdb-ripple-init=""
                                className="btn text-white btn-floating m-1"
                                style={{ backgroundColor: "#ac2bac" }}
                                href="https://www.instagram.com/luontogo.2024/"
                                role="button"
                            >
                                <i className="fab fa-instagram" />
                            </a>
                        </section>
                        {/* Section: Social media */}
                    </div>
                    {/* Grid container */}
                    {/* Copyright */}
                    <div
                        className="text-center p-3"
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                    >
                        © {this.firstNewDate()} - {this.secondNewDate()} Copyright: LuontoGo                        
                    </div>
                    {/* Copyright */}
                </footer>

            </React.Fragment>
        ); //end retur
    } //end render
} //end class

// EXPORT HEADER (withTransaction)
export default withTranslation()(Footer);